<template>
  <v-container ma-0 pa-0>
    <v-file-input
      v-if="items && items.existing && items.existing.length < limitNumberFile"
      v-model="newUpload"
      v-bind="$attrs"
      :placeholder="placeholder"
      prepend-icon="mdi-paperclip"
      :loading="loading"
      :disabled="disabled || (items && items.existing && items.existing.length >= limitNumberFile)"
      :accept="accept"
      :rules="rulesArray"
      @change="onUpload($event)"
      :error-messages="$error.getValidationError(errors, 'global')"
      @input="$error.clearValidationError(errors, 'global')"
    ></v-file-input>
    <v-text-field
      v-bind="$attrs"
      v-for="item in items.existing"
      :key="item.id"
      prepend-icon="mdi-paperclip"
      :value="item.originalFilename + ' (' + readableBytes(item.size) + ')'"
      :clearable="allowRemoval"
      readonly
      @click:clear="onDeleteItem(item)"
    >
    </v-text-field>
    <GoogleReCaptcha v-if="isPublic" :showLegend="false"></GoogleReCaptcha>
  </v-container>
</template>

<script>
import GoogleReCaptcha from "../display/GoogleReCaptcha.vue";
import ApiService from "../../services/api.service";

export default {
  components: { GoogleReCaptcha },
  name: "UploadField",
  props: {
    value: [Array, Object],
    disabled: Boolean,
    limitNumberFile: {
      type: Number,
      required: false,
      default: 12
    },
    rules: Array,
    allowRemoval: {
      default: true,
      type: Boolean
    },
    uploadOnBehalfOfParticipantId: Number,
    allowedfileExtensions: { type: Array, required: false },
    public: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    loading: false,
    rulesArray: [],
    newUpload: undefined,
    uploadMessage: "",
    items: {
      existing: [],
      deleted: []
    },
    errors: {}
  }),
  methods: {
    onInput() {
      this.$emit("input", this.items);
      this.$emit("change");
    },
    onDeleteItem(item) {
      this.$emit("delete", item);
      const index = this.items.existing.indexOf(item);
      let deletedItem = this.items.existing.splice(index, 1);
      if (deletedItem && deletedItem.length > 0 && deletedItem[0].id) {
        this.items.deleted = this.items.deleted.concat(deletedItem);
      }
      this.$emit("input", this.items);
      this.$emit("change");
    },
    onUpload(event) {
      this.$emit("change", event);
      this.errors = {};
      this.uploadMessage = "";
      let allowedExtensions =
        this.allowedfileExtensions && this.allowedfileExtensions.length > 0
          ? new RegExp("(" + this.allowedfileExtensions.map(x => "." + x).join("|") + ")$", "i")
          : /(.jpg|.jpeg|.png|.pdf|.doc|.docx|.ppt|.xls|.xlsx|.heic)$/i;
      if (this.newUpload && allowedExtensions.exec(this.newUpload.name)) {
        let formData = new FormData();
        formData.append("file", this.newUpload);
        if (this.uploadOnBehalfOfParticipantId) {
          formData.append("participantId", this.uploadOnBehalfOfParticipantId);
        }
        this.loading = true;
        let endpointUrl = this.isPublic ? "/api/uploads/public" : "/api/uploads";

        ApiService.postWithCaptcha(endpointUrl, formData, null, this.isPublic ? "publicUpload" : undefined)
          .then(({ data }) => {
            this.items.existing.push(data);

            this.$emit("input", this.items);
            this.$emit("change");
            this.$emit("newUpload", data);
            this.newUpload = undefined;
            this.uploadMessage = "Uploaded successfully";
          })
          .catch(error => {
            this.errors = ApiService.getErrorsFromResponse(error);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    readableBytes(bytes) {
      var i = Math.floor(Math.log(bytes) / Math.log(1024)),
        sizes = ["B", "kB", "MB", "GB"];
      return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + " " + sizes[i];
    },
    fileValidation(file) {
      this.errors = {};
      let allowedExtensions =
        this.allowedfileExtensions && this.allowedfileExtensions.length > 0
          ? new RegExp("(" + this.allowedfileExtensions.map(x => "." + x).join("|") + ")$", "i")
          : /(.jpg|.jpeg|.png|.pdf|.doc|.docx|.ppt|.xls|.xlsx|.heic)$/i;
      if (file && !allowedExtensions.exec(file.name)) {
        return "Acceptable file types: " + this.accept;
      } else {
        return true;
      }
    },
    resetRulesArray() {
      this.rulesArray = [];
      if (this.rules) {
        this.rulesArray = this.rulesArray.concat(this.rules);
      }
      this.rulesArray = this.rulesArray.concat(v => this.fileValidation(v));
    }
  },
  computed: {
    placeholder() {
      if (this.uploadMessage && this.uploadMessage.length > 0) {
        return this.uploadMessage;
      } else {
        return this.message + "Limit " + this.limitNumberFile + " (" + this.accept + ")";
      }
    },
    accept() {
      if (this.public) return ".jpg, .jpeg, .png, .pdf, .doc, .docx, .ppt, .xls, .xlsx, .heic";
      return this.allowedfileExtensions && this.allowedfileExtensions.length > 0
        ? this.allowedfileExtensions.map(x => "." + x).join(", ")
        : ".jpg, .jpeg, .png, .pdf, .doc, .docx, .ppt, .xls, .xlsx, .heic";
    },
    isPublic() {
      return this.public;
    }
  },
  watch: {
    value: {
      handler(v) {
        if (v && v.existing) {
          this.items.existing = v.existing;
        } else {
          this.items.existing = [];
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    //this.items.existing = this.value.existing;
    this.resetRulesArray();
  }
};
</script>
