<template>
  <v-form v-model="valid" ref="mainForm">
    <v-container>
      <v-row>
        <v-col>
          <v-container>
            <v-layout row wrap>
              <v-flex>
                <v-data-table
                  :headers="claimProductHeaders"
                  :items="
                    localClaimProducts
                      ? localClaimProducts.filter(cp => cp.promotionProduct.product.productKey != 'TRADE_IN_PRODUCT')
                      : null
                  "
                  :item-class="itemBackgroundColor"
                  hide-default-footer
                  disable-pagination
                >
                  <template v-slot:item.promotionProduct.product.productKey="{ item }">
                    <v-row>
                      <v-col :cols="12">
                        <span class="productKeyField">{{ item.promotionProduct.product.productKey }}</span>
                      </v-col>
                      <v-col :cols="12" v-if="askForPurchaseDate || askForPurchasePrice">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon class="mr-2 ml-2" v-bind="attrs" v-on="on" @click="onDeleteProduct(item)"
                              >mdi-delete</v-icon
                            >
                          </template>
                          <span>Delete Product</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon class="mr-2 ml-2" v-bind="attrs" v-on="on" @click="onDuplicateProduct(item)"
                              >mdi-content-duplicate</v-icon
                            >
                          </template>
                          <span>Duplicate Product</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </template>

                  <template v-slot:item.serialNumber="{ item }">
                    <v-row class="py-2">
                      <v-col :cols="12" :xxl="8" v-if="!item.promotionProduct.hideQuantityAndSerialNumber">
                        <v-text-field
                          outlined
                          style="width: calc(1ch * 18)"
                          v-if="item.promotionProduct.enableSerialNumbers"
                          dense
                          :background-color="item.ocrPopulated ? '#bbdefb' : null"
                          :label="$i18n.translate('Serial Number')"
                          v-model="item.serialNumber"
                          prefix="S01 -"
                          @keydown="keyHandler($event)"
                          @input="item.ocrPopulated = false"
                          :class="item.promotionProduct.enableSerialNumbers ? 'required mt-2' : 'mt-2'"
                          :rules="
                            item.promotionProduct.enableSerialNumbers
                              ? [requireSerialNumber(item), checkDuplicatedSerialNumber(item)]
                              : []
                          "
                          :disabled="!item.promotionProduct.enableSerialNumbers"
                          :readonly="!item.promotionProduct.enableSerialNumbers"
                        >
                          <template v-slot:append>
                            <v-tooltip bottom max-width="500" v-if="flags.includeClaimProductsSerialNumberTooltip">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" @click="() => {}">mdi-information-outline</v-icon>
                              </template>
                              <span>
                                {{ $i18n.translate(strings.claimProductsSerialnumberTooltipText) }}
                              </span>
                            </v-tooltip>
                          </template>
                        </v-text-field>
                        <v-chip v-else class="mt-3" label> {{ $i18n.translate("Serial Number") }}: N/A </v-chip>
                      </v-col>

                      <v-col :cols="12" :xxl="6">
                        <v-row v-if="!item.promotionProduct.hideQuantityAndSerialNumber">
                          <v-text-field
                            outlined
                            v-if="!item.promotionProduct.enableSerialNumbers"
                            dense
                            label="Quantity"
                            style="width: calc(1ch * 9)"
                            type="number"
                            min="1"
                            max="9999999"
                            v-model="item.quantity"
                            :readonly="item.promotionProduct.enableSerialNumbers"
                            :disabled="item.promotionProduct.enableSerialNumbers"
                            :rules="!item.promotionProduct.enableSerialNumbers ? rules.quantity : ''"
                            :class="!item.promotionProduct.enableSerialNumbers ? 'required mt-2' : 'mt-2'"
                            :background-color="item.ocrPopulated ? '#bbdefb' : null"
                          ></v-text-field>
                          <v-chip v-else class="mt-3" label>
                            Quantity: 1
                          </v-chip>
                        </v-row>
                        <v-row
                          v-if="
                            item &&
                              item.promotionProduct &&
                              item.promotionProduct.enableCustomField1 &&
                              selectedProgram &&
                              selectedProgram.claimProductCustomFields &&
                              selectedProgram.claimProductCustomFields[0]
                          "
                        >
                          <v-col cols="6">
                            <CustomField
                              v-model="item.customFieldValue1"
                              v-if="
                                item &&
                                  item.promotionProduct &&
                                  item.promotionProduct.enableCustomField1 &&
                                  selectedProgram &&
                                  selectedProgram.claimProductCustomFields &&
                                  selectedProgram.claimProductCustomFields[0]
                              "
                              :customField="selectedProgram.claimProductCustomFields[0]"
                            />
                          </v-col>
                        </v-row>

                        <v-row
                          v-if="
                            item &&
                              item.promotionProduct &&
                              item.promotionProduct.enableCustomField2 &&
                              selectedProgram &&
                              selectedProgram.claimProductCustomFields &&
                              selectedProgram.claimProductCustomFields[1]
                          "
                        >
                          <v-col cols="6">
                            <CustomField
                              v-model="item.customFieldValue2"
                              :customField="selectedProgram.claimProductCustomFields[1]"
                            />
                          </v-col>
                        </v-row>

                        <v-row
                          v-if="
                            item &&
                              item.promotionProduct &&
                              item.promotionProduct.enableCustomField3 &&
                              selectedProgram &&
                              selectedProgram.claimProductCustomFields &&
                              selectedProgram.claimProductCustomFields[2]
                          "
                        >
                          <v-col cols="6">
                            <CustomField
                              v-model="item.customFieldValue3"
                              :customField="selectedProgram.claimProductCustomFields[2]"
                            />
                          </v-col>
                        </v-row>

                        <v-row
                          v-if="
                            item &&
                              item.promotionProduct &&
                              item.promotionProduct.enableCustomField4 &&
                              selectedProgram &&
                              selectedProgram.claimProductCustomFields &&
                              selectedProgram.claimProductCustomFields[3]
                          "
                        >
                          <v-col cols="6">
                            <CustomField
                              v-model="item.customFieldValue4"
                              :customField="selectedProgram.claimProductCustomFields[3]"
                            />
                          </v-col>
                        </v-row>

                        <v-row
                          v-if="
                            item &&
                              item.promotionProduct &&
                              item.promotionProduct.enableCustomField5 &&
                              selectedProgram &&
                              selectedProgram.claimProductCustomFields &&
                              selectedProgram.claimProductCustomFields[4]
                          "
                        >
                          <v-col cols="6">
                            <CustomField
                              v-model="item.customFieldValue5"
                              :customField="selectedProgram.claimProductCustomFields[4]"
                            />
                          </v-col>
                        </v-row>

                        <v-row
                          v-if="
                            item &&
                              item.promotionProduct &&
                              item.promotionProduct.enableCustomField6 &&
                              selectedProgram &&
                              selectedProgram.claimProductCustomFields &&
                              selectedProgram.claimProductCustomFields[5]
                          "
                        >
                          <v-col cols="6">
                            <CustomField
                              v-model="item.customFieldValue6"
                              :customField="selectedProgram.claimProductCustomFields[5]"
                            />
                          </v-col>
                        </v-row>

                        <v-row
                          v-if="
                            item &&
                              item.promotionProduct &&
                              item.promotionProduct.enableCustomField7 &&
                              selectedProgram &&
                              selectedProgram.claimProductCustomFields &&
                              selectedProgram.claimProductCustomFields[6]
                          "
                        >
                          <v-col cols="6">
                            <CustomField
                              v-model="item.customFieldValue7"
                              :customField="selectedProgram.claimProductCustomFields[6]"
                            />
                          </v-col>
                        </v-row>

                        <v-row
                          v-if="
                            item &&
                              item.promotionProduct &&
                              item.promotionProduct.enableCustomField8 &&
                              selectedProgram &&
                              selectedProgram.claimProductCustomFields &&
                              selectedProgram.claimProductCustomFields[7]
                          "
                        >
                          <v-col cols="6">
                            <CustomField
                              v-model="item.customFieldValue8"
                              :customField="selectedProgram.claimProductCustomFields[7]"
                            />
                          </v-col>
                        </v-row>

                        <v-row
                          v-if="
                            item &&
                              item.promotionProduct &&
                              item.promotionProduct.enableCustomField9 &&
                              selectedProgram &&
                              selectedProgram.claimProductCustomFields &&
                              selectedProgram.claimProductCustomFields[8]
                          "
                        >
                          <v-col cols="6">
                            <CustomField
                              v-model="item.customFieldValue9"
                              :customField="selectedProgram.claimProductCustomFields[8]"
                            />
                          </v-col>
                        </v-row>

                        <v-row
                          v-if="
                            item &&
                              item.promotionProduct &&
                              item.promotionProduct.enableCustomField10 &&
                              selectedProgram &&
                              selectedProgram.claimProductCustomFields &&
                              selectedProgram.claimProductCustomFields[9]
                          "
                        >
                          <v-col cols="6">
                            <CustomField
                              v-model="item.customFieldValue10"
                              :customField="selectedProgram.claimProductCustomFields[9]"
                            />
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col :cols="item.promotionProduct.allowPriceEntry ? 5 : 0">
                        <CurrencyField
                          outlined
                          dense
                          label="Purchase Price"
                          style="width: calc(1ch * 14)"
                          v-model="item.priceAmount"
                          v-if="item.promotionProduct.allowPriceEntry"
                          :class="flags.markPurchasePriceAsRequired ? 'required mt-2' : 'mt-2'"
                          :rules="
                            rules.claimProductsPriceAmount
                              ? rules.claimProductsPriceAmount
                              : [v => !!v || 'Price is required']
                          "
                          :country="promotion.country"
                        ></CurrencyField>
                      </v-col>
                    </v-row>
                  </template>

                  <template v-slot:item.purchaseData="{ item }">
                    <v-row class="py-2">
                      <v-col :cols="12">
                        <CurrencyField
                          v-if="askForPurchasePrice"
                          outlined
                          dense
                          label="Price"
                          style="width: calc(1ch * 14)"
                          @input="setCleanPrice($event, item)"
                          :class="flags.markPurchasePriceAsRequired ? 'required mt-2' : 'mt-2'"
                          :rules="
                            rules.claimProductsPriceAmount
                              ? rules.claimProductsPriceAmount
                              : [v => !!v || 'Price is required']
                          "
                          :country="promotion.country"
                        ></CurrencyField>
                      </v-col>
                      <v-col :cols="12" v-if="askForPurchaseDate">
                        <DateTimePickerField
                          label="Purchase Date"
                          style="width: calc(1ch * 20)"
                          :rules="
                            rules.claimProductsPurchaseDate
                              ? rules.claimProductsPurchaseDate
                              : [v => !!v || 'Purchase Date is required']
                          "
                          :required="true"
                          format="MM/DD/YYYY"
                          defaultTime="00:00"
                          onlyDate
                          v-model="item.purchaseDate"
                        ></DateTimePickerField>
                      </v-col>
                    </v-row>
                  </template>

                  <template v-slot:item.actions="{ item }" v-if="!askForPurchaseDate && !askForPurchasePrice">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mr-2 ml-2" v-bind="attrs" v-on="on" @click="onDeleteProduct(item)"
                          >mdi-delete</v-icon
                        >
                      </template>
                      <span>Delete Product</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mr-2 ml-2" v-bind="attrs" v-on="on" @click="onDuplicateProduct(item)"
                          >mdi-content-duplicate</v-icon
                        >
                      </template>
                      <span>Duplicate Product</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>
          </v-container>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col>
          <v-autocomplete
            dense
            :placeholder="strings.productListText"
            outlined
            :items="
              promotion && promotion.promotionProducts ? sortedProductsAlphabetically(promotion.promotionProducts) : []
            "
            v-model="selectedPromotionProducts"
            label="Add a Product"
            item-value="id"
            :item-text="
              item => {
                return item && item.product ? item.product.productKey : '';
              }
            "
            multiple
            return-object
            :rules="[requireProduct]"
            class="required"
            hint="You must select at least 1 product for this claim"
            attach
            auto
            @input="searchInput = null"
            :search-input.sync="searchInput"
            :menu-props="{ zIndex: '9999', maxHeight: '270' }"
            ref="menu"
          >
            <template v-slot:append-outer>
              <v-slide-x-reverse-transition mode="out-in">
                <v-btn
                  class="primary mt-n2"
                  @click="
                    onAddProduct();
                    closeMenu();
                  "
                  >Add</v-btn
                >
              </v-slide-x-reverse-transition>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import CustomField from "@/gapp-components/components/fields/CustomField";
import CurrencyField from "@/gapp-components/components/fields/CurrencyField";
import DateTimePickerField from "@/gapp-components/components/fields/DateTimePickerField";

import { mapGetters, mapActions } from "vuex";
export default {
  name: "InvoiceProductsForm",
  components: {
    CustomField,
    CurrencyField,
    DateTimePickerField
  },
  computed: {
    ...mapGetters([
      "promotion",
      "claimProducts",
      "isPublic",
      "objects",
      "strings",
      "flags",
      "rules",
      "selectedProgram"
    ]),
    claimProductHeaders() {
      if (!this.allClaimProductHeaders) return [];

      let claimProductHeadersResult = this.allClaimProductHeaders;

      if (!this.askForPurchaseDate && !this.askForPurchasePrice) {
        claimProductHeadersResult = claimProductHeadersResult.filter(cph => cph.value != "purchaseData");
      } else {
        claimProductHeadersResult = claimProductHeadersResult.filter(cph => cph.value != "actions");
      }

      return claimProductHeadersResult;
    },
    textFieldMask() {
      if (this.strings && this.strings.mask && this.objects && this.objects.tokens) {
        let mask = this.strings.mask;
        let tokens = this.objects.tokens;
        return { mask: mask, tokens: tokens };
      }
      // if no mask, then create a generic mask of 100 (length of serial number field)
      return {
        mask: "*".repeat(100),
        tokens: {
          "*": { pattern: /./ }
        }
      };
    }
  },
  props: {
    askForPurchaseDate: {
      type: Boolean,
      default: false
    },
    askForPurchasePrice: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    allClaimProductHeaders: [
      {
        value: "promotionProduct.product.productKey",
        text: "Product Number",
        align: "left"
      },
      {
        value: "promotionProduct.product.name",
        text: "Product Name",
        align: "left"
      },
      {
        value: "promotionProduct.product.productCategory.name",
        text: "Product Category",
        align: "left"
      },
      {
        value: "serialNumber",
        text: "Details",
        align: "left",
        sortable: false
      },
      {
        value: "purchaseData",
        text: "Purchase Data",
        align: "center",
        sortable: false
      },
      {
        value: "actions",
        text: "",
        align: "right",
        sortable: false
      }
    ],
    localClaimProducts: [],
    searchInput: null,
    selectedPromotionProducts: [],
    localRules: {
      required: [v => !!v || "Field is required"],
      participant: [v => typeof v == "object" || "Participant is required"],
      quantity: [v => !!v || "Quantity is required", v => (!!v && v <= 2147483647) || "Invalid Quantity value"]
    },
    valid: false
  }),
  mounted() {},
  methods: {
    ...mapActions(["updateClaimProducts"]),
    setCleanPrice(value, item) {
      if (!value || value == "") return;
      let cleanString = value.replace(/[^0-9.]/g, "");

      item.priceAmount = cleanString;
    },
    itemBackgroundColor(item) {
      if (item.ocrPopulated) {
        return "ocrPopulated";
      }
    },
    keyHandler(v) {
      if (v.key == " " || v.key == ",") {
        event.preventDefault();
      }
    },
    onDeleteProduct(claimProduct) {
      const index = this.localClaimProducts.indexOf(claimProduct);
      this.localClaimProducts.splice(index, 1);
    },
    onDuplicateProduct(claimProduct) {
      let claimProductCopy = { ...claimProduct };
      claimProductCopy.serialNumber = "";
      claimProductCopy.ocrPopulated = false;

      this.localClaimProducts = this.localClaimProducts.concat(claimProductCopy);
    },
    closeMenu() {
      if (this.$refs && this.$refs.menu) {
        this.$refs.menu.isMenuActive = false;
      }
    },
    onAddProduct() {
      let promotionProducts = this.localClaimProducts;
      this.selectedPromotionProducts.some(promotionProduct => {
        let found = promotionProducts.findIndex(
          needle => needle.promotionProduct.product.id == promotionProduct.product.id
        );
        if (found == -1) {
          if (!this.isPublic) {
            this.$api.get("/api/promotionProducts/" + promotionProduct.id + "/allowPriceEntry").then(res => {
              promotionProduct.allowPriceEntry = res.data;
              promotionProducts = promotionProducts.concat([
                {
                  promotionProduct: promotionProduct
                }
              ]);
              this.selectedPromotionProducts = [];
              this.$set(this, "localClaimProducts", promotionProducts);
              this.$refs.mainForm.validate();
            });
          } else {
            promotionProducts = promotionProducts.concat([
              {
                promotionProduct: promotionProduct
              }
            ]);
            this.selectedPromotionProducts = [];
            this.$set(this, "localClaimProducts", promotionProducts);
          }
        } else {
          if (promotionProduct.enableSerialNumbers) {
            promotionProducts = promotionProducts.concat([
              {
                promotionProduct: promotionProduct
              }
            ]);
          }
        }
      });

      this.localClaimProducts = promotionProducts;
      this.selectedPromotionProducts = [];
    },
    requireProduct() {
      if (this.localClaimProducts.length > 0) {
        return true;
      } else {
        return "At least 1 product is required";
      }
    },
    checkDuplicatedSerialNumber(item) {
      let count = 0;
      this.localClaimProducts.forEach(element => {
        if (
          element.serialNumber &&
          element.promotionProduct.product.id == item.promotionProduct.product.id &&
          element.serialNumber == item.serialNumber
        ) {
          count++;
        }
      });
      if (count > 1) {
        return this.$i18n.translate("Serial Number") + " duplicated";
      }
      return true;
    },
    requireSerialNumber(item) {
      if (item.promotionProduct.enableSerialNumbers) {
        if (item.serialNumber) {
          if (item.promotionProduct.product.serialNumberRegex) {
            let regex = RegExp("^" + item.promotionProduct.product.serialNumberRegex);
            if (regex.test(item.serialNumber)) {
              return true;
            } else {
              return "Invalid " + this.$i18n.translate("Serial Number");
            }
          } else {
            return true;
          }
        } else {
          return this.$i18n.translate("Serial Number") + " required";
        }
      } else {
        return false;
      }
    },
    formatSerialNumber(item) {
      if (item.serialNumber) {
        if (item.serialNumber.startsWith("S01-")) {
          item.serialNumber = item.serialNumber.substring(4);
        }
        if (item.serialNumber.startsWith("S01")) {
          item.serialNumber = item.serialNumber.substring(3);
        }
        if (item.serialNumber.length < 7) {
          item.serialNumber = item.serialNumber.padStart(7, "0");
        }
      }
    },
    sortedProductsAlphabetically(products) {
      let sorted = products.slice().sort((a, b) => {
        return a.product.productKey.localeCompare(b.product.productKey);
      });
      return sorted.filter(product => product.product.productKey != "TRADE_IN_PRODUCT");
    }
  },
  watch: {
    localClaimProducts: {
      deep: true,
      immediate: false,
      handler(v) {
        if (!v) throw new Error("y");
        this.updateClaimProducts(v);
        if (this.$refs.mainForm) {
          this.$refs.mainForm.validate();
        }
      }
    },
    claimProducts: {
      deep: true,
      immediate: true,
      handler(v) {
        this.localClaimProducts = v;
      }
    },
    valid: {
      immediate: true,
      handler(v) {
        this.$emit("validation", v);
      }
    }
  },
  created() {
    this.$root.$on("InvoiceProductsForm-validate", () => {
      if (this.$refs.mainForm) {
        this.$refs.mainForm.validate();
      }
    });
  }
};
</script>
