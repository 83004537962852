<template>
  <v-alert type="warning" border="top" dense v-bind="$attrs" v-on="$listeners" v-if="shouldShowAlert">
    {{ message ? message : errors.message }}
    <div v-if="showValidationErrors && availableValidationErrors">
      <ul>
        <li v-for="(key, i) in Object.keys(errors.validationErrors)" :key="i" style="margin: 0px; padding 0px">
          {{ !isNumeric(key) ? firstLetterToUpperCase(key) + ": " : "" }} {{ errors.validationErrors[key] }}
        </li>
      </ul>
    </div>
  </v-alert>
</template>

<script>
export default {
  name: "ApiError",
  props: {
    errors: {
      type: Object,
      default: null
    },
    message: {
      type: String,
      default: null
    },
    showValidationErrors: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    isNumeric(str) {
      if (typeof str != "string") return false;
      return !isNaN(str) && !isNaN(parseFloat(str));
    },
    firstLetterToUpperCase(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  },
  computed: {
    shouldShowAlert() {
      return (
        this.message ||
        (this.errors && this.errors.validationErrors && Object.keys(this.errors.validationErrors).length > 0) ||
        (this.errors && this.errors.message)
      );
    },
    availableValidationErrors() {
      return this.errors && this.errors.validationErrors && Object.keys(this.errors.validationErrors).length > 0;
    }
  }
};
</script>
