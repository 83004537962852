<template>
  <v-container>
    <v-row v-if="loading">
      <v-progress-circular :size="20" indeterminate color="primary"></v-progress-circular>
    </v-row>
    <v-row v-else-if="errors">
      <v-col>
        <h2 class="mb-4">
          Unable to Calculate Potential Earnings
        </h2>

        <p class="mb-4">
          We are unable to calculate potential earnings on this claim due to the following error. Please adjust your
          claim details before proceeding.
        </p>

        <ApiError :errors="errors" class="mb-4"> </ApiError>
        <span
          v-if="errors && strings && strings.errorsAdditionalMessage"
          v-html="strings.errorsAdditionalMessage"
        ></span>
        <v-btn @click="$emit('previousStep')" text class="mr-2">{{ $i18n.translate("Back") }}</v-btn>
        <v-btn @click="$emit('cancel')">{{ $i18n.translate("Cancel") }}</v-btn>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12" sm="12" md="9" lg="9" xl="9" class="pa-7">
        <h2 class="text-center mb-4">
          This claim has potential earnings of
          <b>
            {{ total | toNumber(2, selectedProgram) }}
            <span v-if="availableAwardVehicles && availableAwardVehicles.length > 0">
              {{ availableAwardVehicles[0].currency.name }}
            </span>
          </b>
          !
        </h2>

        <h3 class="text-center mb-4">
          {{
            automaticallySelected
              ? "Click the Continue button to review all Claim Details and Submit Claim"
              : "Please select your preferred payout"
          }}
        </h3>

        <AwardVehicleSelectionPanel
          buttonSelectMeText="Select"
          buttonSelectedText="Selected"
          :availableAwardVehicles="availableAwardVehicles"
          v-model="localSelectedAwardVehicle"
          v-if="!loading"
          :claimAmount="total"
          :public="isPublic"
          :customAddress="customAddressObject"
        ></AwardVehicleSelectionPanel>

        <br />
        <div class="text-center">
          <v-btn @click="$emit('previousStep')" text class="mr-2">{{ $i18n.translate("Back") }}</v-btn>
          <v-btn color="primary" :disabled="!selectedAwardVehicle" @click="$emit('nextStep')" class="mr-2">
            Continue
          </v-btn>
          <v-btn @click="$emit('cancel')" text>{{ $i18n.translate("Cancel") }}</v-btn>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="grey lighten-3 pa-7">
        <h3 class="pb-2"><v-icon color="primary">mdi-information-outline</v-icon> Payout Selection</h3>
        <slot name="payout-instructions">
          <ul v-if="awardVehicleInstructions && awardVehicleInstructions.length > 0">
            <li class="pt-2 pb-2" v-for="instruction in awardVehicleInstructions" :key="instruction.id">
              <span v-if="instruction.message" class="d-block">{{ instruction.message }}</span>
              <span v-if="instruction.note" class="d-block"><strong>Note: </strong> {{ instruction.note }}. </span>
            </li>
          </ul>
          <ul
            v-if="
              availableAwardVehicles &&
                availableAwardVehicles.length > 0 &&
                availableAwardVehicles[0].minimumPayoutAmount
            "
          >
            <li class="pt-2 pb-2">
              <span class="d-block pt-2"
                ><strong>Reminder:</strong> {{ availableAwardVehicles[0].name }} requires a minimum claim amount of ${{
                  availableAwardVehicles[0].minimumPayoutAmount
                }}
                USD</span
              >
            </li>
          </ul>
        </slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AwardVehicleSelectionPanel from "@/gapp-components/components/common/awardVehicle/AwardVehicleSelectionPanel.vue";
import ApiError from "@/gapp-components/components/display/ApiError.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: { ApiError, AwardVehicleSelectionPanel },
  name: "AwardVehicleSelectionStep",
  props: {
    active: {
      type: Boolean,
      default: false
    },
    publicParticipantTypeKey: {
      type: String
    }
  },
  data: () => ({
    errors: null,
    availableAwardVehicles: [],
    awardVehicleInstructions: [],
    localSelectedAwardVehicle: null,
    loading: false,
    automaticallySelected: false,
    total: 0
  }),
  methods: {
    ...mapActions(["updateSelectedAwardVehicle"]),
    findClaimFieldValue(type) {
      let claimField = this.claimFields.find(claimField => claimField.claimFieldType.name == type);
      if (claimField) {
        return this.claimFieldValues[claimField.id] ? this.claimFieldValues[claimField.id] : null;
      }
      return null;
    },

    buildAddressString() {
      let addressParts = [];

      let address1 = this.findClaimFieldValue("END_USER_ADDRESS1_TYPE");
      if (address1 && address1.trim().length > 0) {
        addressParts.push(address1.trim());
      }

      let address2 = this.findClaimFieldValue("END_USER_ADDRESS2_TYPE");
      if (address2 && address2.trim().length > 0) {
        addressParts.push(address2.trim());
      }

      let city = this.findClaimFieldValue("END_USER_CITY_TYPE");
      if (city && city.trim().length > 0) {
        addressParts.push(city.trim());
      }

      let region = this.findClaimFieldValue("END_USER_REGION_TYPE");
      if (region && region.isoAlpha2 && region.isoAlpha2.trim().length > 0) {
        addressParts.push(region.isoAlpha2.trim());
      }

      let postalCode = this.findClaimFieldValue("END_USER_POSTAL_CODE_TYPE");
      if (postalCode && postalCode.trim().length > 0) {
        addressParts.push(postalCode.trim());
      }

      let country = this.findClaimFieldValue("END_USER_COUNTRY_TYPE");
      if (country && country.name && country.name.trim().length > 0) {
        addressParts.push(country.name.trim());
      }

      return addressParts.join(", ");
    },

    async loadAwardVehicleRelatedData() {
      if (this.participantSelectedToClaimOnBehalf && this.participantSelectedToClaimOnBehalf.id) {
        this.availableAwardVehicles = await this.$awardvehicle.getAvailableAwardVehicles(
          this.participantSelectedToClaimOnBehalf,
          this.promotion,
          this.isPublic
        );
        this.localSelectedAwardVehicle = await this.$awardvehicle.getPreferredAwardVehicle(
          this.participantSelectedToClaimOnBehalf
        );
      } else if (this.selectedParticipant) {
        this.availableAwardVehicles = await this.$awardvehicle.getAvailableAwardVehicles(
          this.selectedParticipant,
          this.promotion,
          this.isPublic
        );
        this.localSelectedAwardVehicle = await this.$awardvehicle.getPreferredAwardVehicle(this.selectedParticipant);
      } else {
        this.availableAwardVehicles = await this.$awardvehicle.getAvailableAwardVehicles(
          null,
          this.promotion,
          this.isPublic
        );
      }
      let matchingAwardVehicle = null;
      if (this.localSelectedAwardVehicle) {
        matchingAwardVehicle = this.availableAwardVehicles.find(elem => elem.id == this.localSelectedAwardVehicle.id);
      }
      if (matchingAwardVehicle) {
        this.localSelectedAwardVehicle = matchingAwardVehicle;
      } else if (this.availableAwardVehicles.length == 1) {
        this.localSelectedAwardVehicle = this.availableAwardVehicles[0];
        this.automaticallySelected = true;
      } else {
        this.localSelectedAwardVehicle = null;
      }

      if (this.availableAwardVehicles && this.availableAwardVehicles.length > 0) {
        this.$api
          .get("/api/awardVehicleInstructions/byAwardVehicleId/" + this.availableAwardVehicles[0].id + "/public")
          .then(({ data }) => {
            this.awardVehicleInstructions = data;
          });
      }
    }
  },
  watch: {
    localSelectedAwardVehicle: {
      immediate: true,
      deep: true,
      handler(v) {
        this.updateSelectedAwardVehicle(v);
      }
    },
    selectedAwardVehicle: {
      immediate: true,
      deep: true,
      handler(v) {
        this.localSelectedAwardVehicle = v;
      }
    },
    participantSelectedToClaimOnBehalf: {
      deep: true,
      handler() {
        this.loading = true;
        this.loadAwardVehicleRelatedData();
        this.loading = false;
      }
    },
    active: {
      deep: true,
      handler(v) {
        if (this.isPublic) {
          if (v) {
            this.loading = true;
            this.errors = null;
            this.$claim
              .calculateTotalPublicClaimAmount(this.availableAwardVehicles, this.publicParticipantTypeKey)
              .then(value => {
                this.total = value;
              })
              .catch(e => {
                this.errors = this.$api.getErrorsFromResponse(e);
              })
              .finally(() => {
                this.loading = false;
              });
          }
        } else {
          if (v) {
            this.loading = true;
            this.errors = null;
            this.$claim
              .calculateTotalClaimAmount(this.availableAwardVehicles)
              .then(value => {
                this.total = value;
              })
              .catch(e => {
                this.errors = this.$api.getErrorsFromResponse(e);
              })
              .finally(() => {
                this.loading = false;
              });
          }
        }
      }
    }
  },
  mounted() {
    this.loading = true;
    this.loadAwardVehicleRelatedData();
    this.loading = false;
  },
  computed: {
    getFullAddressFromClaimFieldValues() {
      if (this.claimFieldValues) {
        return this.buildAddressString(this.claimFieldValues);
      } else {
        return null;
      }
    },
    customAddressObject() {
      return { fullAddress: this.getFullAddressFromClaimFieldValues };
    },
    ...mapGetters([
      "selectedAwardVehicle",
      "participantSelectedToClaimOnBehalf",
      "promotion",
      "selectedParticipant",
      "claimProducts",
      "isPublic",
      "claimFieldValues",
      "claimFields",
      "strings",
      "selectedProgram"
    ])
  }
};
</script>
