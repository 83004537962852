<template>
  <div v-if="customField && customField.name">
    <v-text-field
      v-if="customField && customField.fieldType.name == 'STRING'"
      :label="customField.name"
      v-model="v"
      type="String"
      maxlength="100"
      :clearable="clearable"
      :disabled="disabled"
      :readonly="readonly"
      :outlined="outlined"
      :hint="hintValue"
    />
    <v-text-field
      v-else-if="customField && customField.name && customField.fieldType.name == 'INTEGER'"
      :label="customField.name"
      v-model="v"
      type="Number"
      clearable
      :disabled="disabled"
      :hint="hintValue"
    />
    <v-text-field
      v-else-if="customField && customField.name && customField.fieldType.name == 'DECIMAL'"
      :label="customField.name"
      v-model="v"
      type="Number"
      clearable
      :disabled="disabled"
      :hint="hintValue"
    />
    <v-select
      v-else-if="customField && customField.fieldType.name == 'ARRAY'"
      :label="customField.name"
      v-model="v"
      :items="items"
      clearable
      :disabled="disabled"
      :hint="hintValue"
    >
    </v-select>
    <v-switch
      v-else-if="customField && customField.fieldType.name == 'BOOLEAN'"
      :label="customField.name"
      true-value="true"
      false-value="false"
      v-model="v"
      :disabled="disabled"
      :hint="hintValue"
    />
    <DateTimePickerField
      v-else-if="customField && customField.fieldType.name == 'DATE'"
      :label="customField.name"
      v-model="v"
      :disabled="disabled"
      defaultTime="00:00"
      :hint="hintValue"
      onlyDate
      format="MM/DD/YYYY"
      returnFormat="YYYY-MM-DD"
    ></DateTimePickerField>
    <DateTimePickerField
      v-else-if="customField && customField.fieldType.name == 'DATETIME'"
      :label="customField.name"
      v-model="v"
      :disabled="disabled"
      defaultTime="00:00"
      :hint="hintValue"
    ></DateTimePickerField>
  </div>
</template>

<script>
import DateTimePickerField from "./DateTimePickerField.vue";

export default {
  name: "CustomField",
  props: {
    customField: Object,
    value: String,
    disabled: Boolean,
    readonly: Boolean,
    clearable: Boolean,
    outlined: Boolean
  },
  components: { DateTimePickerField },
  data: () => ({
    items: [],
    v: ""
  }),
  mounted() {
    this.v = this.value;
  },
  methods: {
    populateItems() {
      this.items = [];
      if (this.customField && this.customField.fieldValues && this.customField.fieldValues.length > 0) {
        this.items = this.customField.fieldValues.split("|").map(e => e.trim());
      }
      return this.items;
    }
  },
  watch: {
    value() {
      this.v = this.value;
    },
    v() {
      if (this.customField && this.customField.fieldType.name == "BOOLEAN" && this.v != undefined && this.v != null) {
        this.$emit("input", this.v.toString());
      } else {
        this.$emit("input", this.v);
      }
    },
    customField: {
      immediate: true,
      deep: true,
      handler() {
        this.populateItems();
      }
    }
  },
  computed: {
    hintValue() {
      return this.customField && this.customField.hint && this.customField.hint != "" ? this.customField.hint : "";
    }
  }
};
</script>
